<template>
  <div class="a-page" style="background: #fff">
    <loader v-if="loader"/>
    <header class="s-header-image" style="">
      <div class="s-header-heading" data-effect="heading">
        <h3 class="f-st" style="opacity: 1;">{{data.section[0].small_description}}</h3>
        <h2 class="f-hl" style="">
          <div
            class="f-hl-line"
            style="display: block; text-align: center; position: relative;"
          >
            <div
              style="position: relative; display: inline-block; transform: translate(0px, 0px); opacity: 1; margin-right: 15px"
              class="f-hl-word" v-if="data && data.section"
            >
              {{data.section[0].heading}} &nbsp;&nbsp;
            </div>
          </div>
        </h2>
      </div>
      <div class="s-header-src"></div>
      <div class="s-header-background" data-background="#FFFFFF"></div>
    </header>
    <section class="s-text">
      <div class="s-text-container">
        <div class="s-text-body" v-for="(items,index) in data.section" :key="index">
          <h3 class="f-hr" v-if="items.heading">
              <!-- {{items.heading}} -->
          </h3>
            <div v-for="(itm, ind) in items.data" :key="ind">
                <h3 class="f-hr" v-if="itm.heading">
                     {{itm.heading}}
                </h3>
                <p class="f-pr" v-if="itm.description">
                    {{itm.description}}
                </p>
            </div>
        </div>
        <div class="s-text-background" data-background="#FFFFFF"></div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
export default {
    data(){
        return{
            data: {},
            loader: true
        }
    },
    components:{
      loader: () => import('../components/loader.vue'),
    },
    watch: {
        "$route.params": {
        handler(newValue, preValue) {
            this.init()
        },
        immediate: true
        }
    },
    created(){
        // console.log(this.$route.params.pageName)
        this.init()
    },
    methods:{
        init(){
          this.loader = true
            var filters = [{
                        match_phrase: {
                            event: this.$store.state.event
                        }
                    },
                    {
                        match_phrase: {
                            name: this.$route.params.pageName
                        }
                    }
                ]
                axios
                    .post(
                        this.$store.state.api + "theme/_search", {
                            from: 0,
                            size: 5000,
                            query: {
                                bool: {
                                    must: filters
                                }
                            }
                        }
                    ).then(response => {
                        this.data= response.data.hits.hits[0]._source
                        this.loader = false
                    })
        },
        trimName(name) {
            // console.log(name)
            if (name)
            return name
            .replace(new RegExp("-", "g"), " ")
            .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "")
        },
    }
}
</script>

<style>
.f-hl-word{
    text-transform: capitalize;
}
section, figure {
    padding-bottom: 6.25vw;
    margin: 0 !important;
}
</style>